import React from "react"
import BlockContent from "@sanity/block-content-to-react"

/* Import Local Style(s) */
import "./audio.css"

const Audio = ({ src, caption }) => (
  <div className="documentation-audio">
    <audio src={src} controls>
      <track kind="captions" />
    </audio>
    {caption && (
      <div className="caption">
        <BlockContent blocks={caption} />
      </div>
    )}
  </div>
)

export default Audio
