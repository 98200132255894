import React from "react"
import { Link } from "gatsby"

/* Import Local Components */
import Arrow from "../arrow/arrow"

/* Import Local Style(s) */
import "./footer.css"

const Footer = ({ previousProjectPath, nextProjectPath }) => {
  return (
    <div className="project-footer">
      <div className="project-footer-link">
        <Link to="/projects">Back to Index</Link>
      </div>
      <div className="project-footer-controls">
        <Link to="/projects">
          <Arrow direction={"up"} />
        </Link>
        <Link to={previousProjectPath}>
          <Arrow direction={"left"} />
        </Link>
        <Link to={nextProjectPath}>
          <Arrow direction={"right"} />
        </Link>
      </div>
    </div>
  )
}

export default Footer
