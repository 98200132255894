import React from "react"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"

/* Import Local Style(s) */
import "./documentation-image.css"

const DocumentationImage = ({ fluid, alt, caption, backgroundColor }) => (
  <div className="documentation-image">
    <Img fluid={fluid} alt={alt ? alt : ""} backgroundColor={backgroundColor} />
    {caption && (
      <div className="caption">
        <BlockContent blocks={caption} />
      </div>
    )}
  </div>
)

export default DocumentationImage
