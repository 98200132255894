import React from "react"

import "./arrow.css"

const Arrow = ({ direction }) => {
  switch (direction) {
    case "left":
      return (
        <svg
          alt="Arrow Left"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 18.7 12.3"
          style={{ height: "15px" }}
        >
          <g id="Layer_2_1_">
            <g id="Layer_1-2">
              <polyline
                className="arrow-stroke"
                points="6.4,0.3 0.6,6.2 6.4,12 		"
              />
              <line
                className="arrow-stroke"
                x1="18.7"
                y1="6.3"
                x2="0.7"
                y2="6.3"
              />
            </g>
          </g>
        </svg>
      )
    case "right":
      return (
        <svg
          alt="Arrow Right"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18.74 12.32"
          style={{ height: "15px" }}
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <polyline
                className="arrow-stroke"
                points="12.3 12.04 18.18 6.16 12.3 0.28"
              />
              <line className="arrow-stroke" x1="18" y1="6.28" y2="6.28" />
            </g>
          </g>
        </svg>
      )
    case "up":
      return (
        <svg
          alt="Arrow Up"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12.32 18.07"
          style={{ height: "20px", paddingRight: "0.5rem" }}
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <polyline
                className="arrow-stroke"
                points="12.04 6.45 6.16 0.57 0.28 6.45"
              />
              <line
                className="arrow-stroke"
                x1="6.16"
                y1="0.57"
                x2="6.07"
                y2="18.07"
              />
            </g>
          </g>
        </svg>
      )

    default:
      return null
  }
}

export default Arrow
