import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import ReactPlayer from "react-player"

/* Import Local Style(s) */
import "./mux-video.css"

const MuxVideo = ({ playbackId, caption }) => (
  <div className="documentation-video">
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        controls
        width="100%"
        height="100%"
        url={`https://stream.mux.com/${playbackId}.m3u8`}
      />
    </div>
    {caption && (
      <div className="caption">
        <BlockContent blocks={caption} />
      </div>
    )}
  </div>
)

export default MuxVideo
