import React from "react"
import { Link } from "gatsby"

/* Import Local Components */
import Arrow from "../arrow/arrow"

/* Import Local Style(s) */
import "./header.css"

const Header = ({ title, previousProjectPath, nextProjectPath }) => (
  <div className="project-header">
    <div className="project-header-project">
      <Link to="/projects">
        <p>The Portal</p>
      </Link>
      <p className="title">{title}</p>
    </div>
    <div className="project-header-controls">
      <Link to="/projects">
        <Arrow direction="up" />
      </Link>
      <Link to={previousProjectPath}>
        <Arrow direction="left" />
      </Link>
      <Link to={nextProjectPath}>
        <Arrow direction="right" />
      </Link>
    </div>
  </div>
)

export default Header
