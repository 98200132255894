import React, { useContext, Fragment } from "react"
import useEventListener from "@use-it/event-listener"
import useScrollPosition from "@react-hook/window-scroll"
import { navigate } from "gatsby"
import { graphql } from "gatsby"

/* Import Global Contexts */
import ProjectsContext from "~context/projects"

/* Import Global Components */
import SEO from "~components/seo"
import Page from "~components/page"

/* Import Local Component(s) */
import Header from "./components/header/header"
import Project from "./components/project/project"
import Footer from "./components/footer/footer"

/* Import Local Style(s) */
import "./project.css"

const ProjectTemplate = ({ data: { sanityProject: project } }) => {
  const [, nextProjectPathname, previousProjectPathname] = useContext(
    ProjectsContext
  )
  const scrollY = useScrollPosition()

  // set listener keys
  const LEFT_KEY = ["37", "ArrowLeft"]
  const RIGHT_KEY = ["39", "ArrowRight"]
  const UP_KEY = ["38", "ArrowUp"]

  // handle listener key presses
  const handler = ({ key }) => {
    if (LEFT_KEY.includes(String(key))) {
      navigate(previousProjectPathname)
    } else if (RIGHT_KEY.includes(String(key))) {
      navigate(nextProjectPathname)
    } else if (UP_KEY.includes(String(key))) {
      if (scrollY <= 0) {
        navigate("/projects")
      }
    }
  }

  useEventListener("keydown", handler)

  return (
    <Fragment>
      <SEO title="Project" />
      <Page
        page="project"
        background={project.practiceReference.desaturatedColor.hex}
      >
        <Header
          title={project.title}
          nextProjectPath={nextProjectPathname}
          previousProjectPath={previousProjectPathname}
        />
        <Project project={project} />
        <Footer
          nextProjectPath={nextProjectPathname}
          previousProjectPath={previousProjectPathname}
        />
      </Page>
    </Fragment>
  )
}

export default ProjectTemplate

export const query = graphql`
  query($id: String!) {
    sanityProject(id: { eq: $id }) {
      _id
      date
      endDate
      title
      documentation {
        ... on SanityAudio {
          _key
          _type
          _rawCaption(resolveReferences: { maxDepth: 10 })
          asset {
            url
          }
        }
        ... on SanityDocumentationImage {
          _key
          _type
          asset {
            url
            fluid {
              ...GatsbySanityImageFluid_noBase64
            }
          }
          _rawCaption(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityMuxSourceVideo {
          _key
          _type
          video {
            asset {
              thumbTime
              playbackId
            }
          }
          _rawCaption(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityPdf {
          _key
          _type
          title
          asset {
            url
          }
        }
      }
      _rawDescription(resolveReferences: { maxDepth: 10 })
      description {
        _key
        _type
        style
        list
        _rawChildren
      }
      partOf {
        _id
        title
      }
      keywords {
        _id
        title
      }
      practiceReference {
        _id
        title
        color {
          hex
        }
        desaturatedColor {
          hex
        }
      }
      links {
        _key
        title
        url
      }
      people
      site
      location
      format
      artists
      ongoing
    }
  }
`
