import React from "react"
import { Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import { add, format } from "date-fns"
import slug from "slug"

/* Import Global Components */
import DocumentationImage from "~components/documentationImage/documentationImage"
import Audio from "~components/audio/audio"
import MuxVideo from "~components/muxVideo/muxVideo"

/* Import Local Style(s) */
import "./project.css"

const Project = ({ project }) => {
  return (
    <div className="project">
      {project.documentation &&
        project.documentation.map(documentation => {
          if (documentation.__typename === "SanityDocumentationImage") {
            if (!documentation.asset) {
              return null
            }
            return (
              <div
                className="documentation documentation-image"
                key={documentation._key}
              >
                <DocumentationImage
                  fluid={documentation.asset.fluid}
                  backgroundColor={`var(--color-${slug(
                    project.practiceReference.title
                  )})`}
                  caption={documentation._rawCaption}
                />
              </div>
            )
          } else if (documentation.__typename === "SanityAudio") {
            if (!documentation.asset) {
              return null
            }
            return (
              <div
                className="documentation documentation-audio"
                key={documentation._key}
              >
                <Audio
                  src={documentation.asset.url}
                  caption={documentation._rawCaption}
                />
              </div>
            )
          } else if (documentation.__typename === "SanityMuxSourceVideo") {
            if (documentation.video === null) {
              return null
            }
            if (
              !documentation.video &&
              !documentation.video.asset &&
              !documentation.video.asset.playbackId
            ) {
              return null
            }
            return (
              <div
                className="documentation documentation-video"
                key={documentation._key}
              >
                <MuxVideo
                  playbackId={documentation.video.asset.playbackId}
                  caption={documentation._rawCaption}
                />
              </div>
            )
          } else {
            return null
          }
        })}
      <div className="description">
        <BlockContent blocks={project._rawDescription} />
        <div className="links">
          {project.documentation.map(documentation => {
            if (
              documentation.__typename === "SanityPdf" &&
              documentation.title
            ) {
              return (
                <a
                  className="link pdf"
                  href={documentation.asset.url}
                  rel="noreferrer"
                  target="_blank"
                  key={documentation._key}
                >
                  {documentation.title} PDF{" "}
                  <span>
                    <svg
                      alt="Arrow Up"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 12.32 18.07"
                      style={{
                        height: "1rem",
                        transform: "translate(0,0.1rem) rotate(180deg)",
                      }}
                    >
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                          <polyline
                            className="arrow-stroke"
                            style={{ strokeWidth: "1px" }}
                            points="12.04 6.45 6.16 0.57 0.28 6.45"
                          />
                          <line
                            className="arrow-stroke"
                            style={{ strokeWidth: "1px" }}
                            x1="6.16"
                            y1="0.57"
                            x2="6.07"
                            y2="18.07"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                </a>
              )
            } else {
              return null
            }
          })}
          {project.links.map(link => {
            if (link.title && link.url) {
              return (
                <a
                  className="link"
                  href={link.url}
                  rel="noreferrer"
                  target="_blank"
                  key={link._key}
                >
                  {link.title}{" "}
                  <span>
                    <svg
                      alt="Arrow Right"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 18.74 12.32"
                      style={{ height: "0.7rem" }}
                    >
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                          <polyline
                            className="arrow-stroke"
                            style={{ strokeWidth: "1" }}
                            points="12.3 12.04 18.18 6.16 12.3 0.28"
                          />
                          <line
                            className="arrow-stroke"
                            style={{ strokeWidth: "1" }}
                            x1="18"
                            y1="6.28"
                            y2="6.28"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                </a>
              )
            } else {
              return null
            }
          })}
        </div>
      </div>
      <ul className="metadata">
        <li className="practice">
          <p className="key">practice</p>
          <Link
            className="content"
            to={`/practice/${slug(project.practiceReference.title, {
              lower: true,
            })}`}
            key={project.practiceReference._id}
          >
            <span>{project.practiceReference.title}</span>
          </Link>
        </li>
        <li>
          <p className="key">title</p>
          <p className="content">{project.title}</p>
        </li>
        {project.site && (
          <li>
            <p className="key">site</p>
            <p className="content">{project.site}</p>
          </li>
        )}
        {project.location && (
          <li>
            <p className="key">location</p>
            <p className="content">{project.location}</p>
          </li>
        )}
        {project.date && (
          <li>
            <p className="key">date</p>
            <p className="content">
              {`${format(
                add(new Date(project.date), {
                  hours: new Date(project.date).getTimezoneOffset() / 60,
                }),
                "MMMM d, yyyy"
              )}`}
              {project.ongoing
                ? " — present"
                : project.endDate && project.endDate !== project.date
                ? ` — ${format(
                    add(new Date(project.endDate), {
                      hours: new Date(project.endDate).getTimezoneOffset() / 60,
                    }),
                    "MMMM d, yyyy"
                  )}`
                : ""}
            </p>
          </li>
        )}
        {project.format && (
          <li className="format">
            <p className="key">format</p>
            <div className="content">
              <Link to={`/format/${slug(project.format, { lower: true })}`}>
                <span>{project.format}</span>
              </Link>
            </div>
          </li>
        )}
        {project.people && (
          <li>
            <p className="key">people</p>
            <p className="content">{project.people}</p>
          </li>
        )}
        {project.partOf.length > 0 && (
          <li className="part-of">
            <p className="key">part of</p>
            <div className="content">
              {project.partOf.map(partOf => (
                <Link
                  to={`/part-of/${slug(partOf.title, { lower: true })}`}
                  key={partOf._id}
                >
                  <span>{partOf.title}</span>
                </Link>
              ))}
            </div>
          </li>
        )}
        {project.keywords.length > 0 && (
          <li className="keywords">
            <p>keywords</p>
            <div className="content">
              {project.keywords.map(keyword => (
                <Link
                  to={`/keyword/${slug(keyword.title, { lower: true })}`}
                  key={keyword._id}
                >
                  <span>{keyword.title}</span>
                </Link>
              ))}
            </div>
          </li>
        )}
      </ul>
    </div>
  )
}

export default Project
